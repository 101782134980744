import getSection from './get-section';

/**
 * @param {string} selector
 * @param {string} section
 * @param {Object} response
 */
const renderSectionFromResponse = (selector, section, response) => {
  document.querySelector(selector).innerHTML = '';
  document.querySelector(selector).innerHTML = getSection(
    response[section],
    selector
  ).innerHTML;
};

export default renderSectionFromResponse;
